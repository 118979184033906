@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/MaterialIcons-Regular.96c47680.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/static/media/MaterialIcons-Regular.0509ab09.woff2) format("woff2"), url(/static/media/MaterialIcons-Regular.29b882f0.woff) format("woff"), url(/static/media/MaterialIcons-Regular.d120c85b.ttf) format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

